

import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import gsap from 'gsap'
import eventBus from '@/utils/event-bus'
import EventType from '@/constants/event-type'
import { getBreakpointValue } from '@/utils/media'
import { Getter } from 'vuex-class'

export interface ScrollerElementAnimationEvent {
    timeline: GSAPTimeline
}

@Component
export default class ScrollerElement extends Vue {
    @Prop({ default: 0 }) x!: number
    @Prop({ default: 0 }) y!: number
    @Prop({ default: 0 }) indexY!: number
    @Prop({ default: 0 }) indexX!: number

    @Getter navigationIsAllowed!: boolean

    windowWidth = window.innerWidth
    windowHeight = window.innerHeight

    enterY!: GSAPTimeline
    leaveY!: GSAPTimeline
    enterX!: GSAPTimeline
    leaveX!: GSAPTimeline
    needsUpdate!: boolean

    get baseX() {
        return this.indexX * this.windowWidth
    }

    get baseY() {
        return this.indexY * this.windowHeight
    }

    get isSlideshow() {
        return this.windowWidth >= getBreakpointValue('lg')
    }

    get isVisible() {
        return (
            this.baseY + this.y <= this.windowHeight &&
            this.baseY + this.y + this.windowHeight > 0 &&
            this.baseX + this.x <= this.windowWidth * 2 &&
            this.baseX + this.x + this.windowWidth * 2 > 0
        )
    }

    render() {
        if (this.$scopedSlots.default) {
            // fix renderless component definition
            /* eslint-disable */
            return this.$scopedSlots.default({
                isVisible: this.isVisible
            }) as any
            /* eslint-enable */
        }
    }

    created() {
        eventBus.$on(EventType.RESIZE, this.onResize)
    }

    mounted() {
        this.init()
    }

    init() {
        this.needsUpdate = true

        this.initEnterX()
        this.initLeaveX()

        if (this.isSlideshow) {
            this.initEnterY()
            this.initLeaveY()
        }

        this.$nextTick(() => {
            this.updateX()

            if (this.isSlideshow) {
                this.updateY()
                this.updatePosition()
            }

            this.needsUpdate = this.isVisible
        })
    }

    disposeTimelines() {
        if (this.enterX) this.disposeTimeline(this.enterX)
        if (this.leaveX) this.disposeTimeline(this.leaveX)
        if (this.enterY) this.disposeTimeline(this.enterY)
        if (this.leaveY) this.disposeTimeline(this.leaveY)
    }

    disposeTimeline(timeline: GSAPTimeline) {
        const children = timeline.getChildren(true, true, false)

        timeline.kill()

        children.forEach(child => {
            const tween = child as GSAPTween

            tween.targets().forEach(target => {
                const element = target as HTMLElement

                if ('nodeType' in element) {
                    gsap.set(element, { clearProps: 'all' })
                }
            })
        })
    }

    initEnterX() {
        this.enterX = gsap.timeline({
            paused: true,
            onComplete: () => {
                this.$emit('enter-x-complete')
            }
        })

        this.$nextTick(() => {
            this.$emit('init-enter-x', { timeline: this.enterX } as ScrollerElementAnimationEvent)
        })
    }
    initLeaveX() {
        this.leaveX = gsap.timeline({
            paused: true,
            onComplete: () => {
                this.$emit('leave-x-complete')
            }
        })

        this.$nextTick(() => {
            this.$emit('init-leave-x', { timeline: this.leaveX } as ScrollerElementAnimationEvent)
        })
    }

    initEnterY() {
        this.enterY = gsap.timeline({ paused: true })

        this.$nextTick(() => {
            this.$emit('init-enter-y', { timeline: this.enterY } as ScrollerElementAnimationEvent)
        })
    }

    initLeaveY() {
        this.leaveY = gsap.timeline({ paused: true })

        this.$nextTick(() => {
            this.$emit('init-leave-y', { timeline: this.leaveY } as ScrollerElementAnimationEvent)
        })
    }

    beforeDestroy() {
        eventBus.$off(EventType.RESIZE, this.onResize)
    }

    updateX() {
        const progress = (this.baseX + this.x) / this.windowWidth

        if (progress >= 0 || (progress < 0 && this.enterX.progress() === 0)) {
            this.enterX.progress(1 - progress)
        } else if (progress >= -1) {
            this.leaveX.progress(progress * -1)
        }
    }

    /**
     *
     * @param force forces the timeline to rerender even if the progress is the same value as before
     */
    updateY(force = false) {
        const ratio = (this.baseY + this.y) / this.windowHeight

        if (ratio >= 0 || (ratio < 0 && this.enterY?.progress() === 0)) {
            const progress = Math.max(Math.min(1 - ratio, 1), 0)

            if (force) {
                this.enterY?.progress(progress > 0 ? progress - 0.01 : progress + 0.01)
            }

            this.enterY?.progress(progress)
        }

        if (ratio < 0 && ratio >= -1) {
            const progress = ratio * -1

            if (force) {
                this.leaveY?.progress(progress > 0 ? progress - 0.01 : progress + 0.01)
            }

            this.leaveY?.progress(progress)
        }
    }

    updatePosition() {
        ;(this.$el as HTMLElement).style.transform = `translate3d(${this.x}px, ${this.isSlideshow ? this.y : 0}px, 0px)`
    }

    clear() {
        ;(this.$el as HTMLElement).style.transform = ''

        this.$emit('clear')
    }

    @Watch('x')
    onXChange() {
        if (!this.needsUpdate) return

        this.updateX()
        this.updatePosition()
    }

    @Watch('y')
    onYChange() {
        if (!this.needsUpdate) return

        this.updateY()
        this.updatePosition()
    }

    @Watch('isVisible')
    onIsVisibleChange() {
        this.needsUpdate = this.isVisible

        this.updateX()
        if (this.isSlideshow) this.updateY(true)
        this.updatePosition()
    }

    @Watch('navigationIsAllowed')
    onNavigationIsAllowedChange() {
        this.disposeTimelines()
        this.init()
    }

    onResize() {
        this.windowWidth = window.innerWidth
        this.windowHeight = window.innerHeight

        this.disposeTimelines()
        this.init()

        if (this.isSlideshow) {
            this.updatePosition()
        } else {
            if (this.x !== 0) this.updatePosition()
            else this.clear()
        }
    }
}
