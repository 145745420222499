import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import Color from '@/constants/color'
import * as Sentry from '@sentry/browser'
import eventBus from '@/utils/event-bus'
import EventType from '@/constants/event-type'
import ScrollerElement, { ScrollerElementAnimationEvent } from '@/components/ScrollerElement.vue'
import { State } from 'vuex-class'
import gsap from 'gsap'
import VueRouter, { Route } from 'vue-router'
import SlideType from '@/constants/slide-type'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import MutationType from '@/constants/mutation-type'
import { resolveSlug } from '@/utils/alias-resolver'
import ModuleNamespace from '@/constants/module-namespace'
import MutationRemoteType from '@/constants/mutation-remote-type'

const MIN_INTERSECTION_RATIO = 0.8
const { isNavigationFailure, NavigationFailureType } = VueRouter

gsap.registerPlugin(ScrollToPlugin)

@Component
export default class Slide extends Vue {
    @Prop() walker!: SlideWalker
    @Prop({ default: 0 }) index!: number
    @Prop({ default: 0 }) indexX!: number
    @Prop() isVisible!: boolean
    @Prop({ default: 0 }) x!: number
    @Prop({ default: 0 }) y!: number
    @Prop() endDragY!: Function
    @Prop({ default: 0 }) currentSlideIndexX!: number
    @Prop() shouldInit!: boolean

    @State('slideIndex') currentIndex!: number
    @State numberSlides!: number
    @State presentationIsVisible!: boolean
    @State transitionIsVisible!: boolean
    @State transitionIsAnimated!: boolean
    @State isSlideshow!: boolean
    @State isPresentingLeader!: boolean

    @Ref() scrollerElement!: ScrollerElement | undefined

    color = Color.LIGHT
    assetsLoaded = false
    assetPromises: Array<Promise<void>> = []
    isEnoughVisible = false
    isInitialLoad = false
    windowWidth = window.innerWidth
    windowHeight = window.innerHeight
    previousIndex = -1
    previousIndexX = -1

    intersectionObserver!: IntersectionObserver

    get isDark(): boolean {
        return this.color === Color.DARK
    }

    get isLight(): boolean {
        return this.color === Color.LIGHT
    }

    get isCurrent(): boolean {
        const isCurrentY = this.index === this.currentIndex
        const isCurrentX = this.currentSlideIndexX === this.indexX

        return isCurrentY && isCurrentX
    }

    get isLast() {
        return this.index === this.numberSlides - 1
    }

    mounted() {
        if (this.scrollerElement) {
            this.scrollerElement.$on('init-enter-x', this.initEnterX)
            this.scrollerElement.$on('init-leave-x', this.initLeaveX)
            this.scrollerElement.$on('init-enter-y', this.initEnterY)
            this.scrollerElement.$on('init-leave-y', this.initLeaveYY)
        }
    }

    beforeDestroy() {
        this.disposeIntersectionObserver()

        eventBus.$off(EventType.RESIZE, this.onResize)
    }

    init() {
        if (this.isCurrent) {
            this.isInitialLoad = true

            this.populateAssetPromises()
            this.loadAssets()

            this.updateUIColor()

            if (!this.isSlideshow) this.$el.scrollIntoView()

            this.commitType()
        }

        this.initIntersectionObserver()

        eventBus.$on(EventType.RESIZE, this.onResize)
    }

    initEnterX(event: ScrollerElementAnimationEvent) {
        this.populateEnterX(event)
    }

    // eslint-disable-next-line
    populateEnterX(event: ScrollerElementAnimationEvent) {
        // override
    }

    initLeaveX(event: ScrollerElementAnimationEvent) {
        this.populateLeaveX(event)
    }

    // eslint-disable-next-line
    populateLeaveX(event: ScrollerElementAnimationEvent) {
        // override
    }

    initEnterY(event: ScrollerElementAnimationEvent) {
        // event.timeline.fromTo(this.$el, { opacity: 0 }, { opacity: 1, duration: 1, ease: 'none' }, 0)

        this.populateEnterY(event)
    }

    // eslint-disable-next-line
    populateEnterY(event: ScrollerElementAnimationEvent) {
        // override
    }

    initLeaveYY(event: ScrollerElementAnimationEvent) {
        // event.timeline.fromTo(this.$el, { opacity: 1 }, { opacity: 0, duration: 1, ease: 'none' }, 0)

        this.populateLeaveY(event)
    }

    // eslint-disable-next-line
    populateLeaveY(event: ScrollerElementAnimationEvent) {
        // override
    }

    shouldAppear() {
        // override here for custom rule indicates if the slide should animate when it appears
        return this.isCurrent
    }

    appear() {
        const timeline = gsap.timeline()

        this.populateAppear(timeline)
    }

    // eslint-disable-next-line
    populateAppear(timeline: GSAPTimeline) {
        // override here for populate the timeline
    }

    initIntersectionObserver() {
        const height = Math.floor(this.windowHeight * (1 - MIN_INTERSECTION_RATIO))

        this.intersectionObserver = new IntersectionObserver(this.onIntersectionObserverChange, {
            rootMargin: `-${height}px 0px -${this.windowHeight - height - 1}px`
        })

        this.intersectionObserver.observe(this.$el)
    }

    disposeIntersectionObserver() {
        if (this.intersectionObserver) this.intersectionObserver.disconnect()
    }

    populateAssetPromises() {
        // populate promises
    }

    loadAssets() {
        const assetsPromise = Promise.all(this.assetPromises)
        const timeoutPromise = new Promise((resolve, reject) => {
            setTimeout(() => {
                reject('Timeout error. The assets of the first slide cannot be loaded.')
            }, 10000)
        })

        Promise.race([assetsPromise, timeoutPromise])
            .catch(error => {
                console.warn(error)
                if (process.env.VUE_APP_SENTRY_DSN) {
                    Sentry.captureException(error)
                }
            })
            .finally(this.onAssetsLoaded)
    }

    updateUIColor() {
        this.$store.commit(MutationType.UI_COLOR, this.color === Color.DARK ? Color.LIGHT : Color.DARK)
    }

    // TODO: remove isEnoughVisible and use directly Intersection Observers?
    afterIsEnoughVisibleChange() {
        //
    }

    afterResize() {
        // override
    }

    setCurrentRouteHash() {
        this.$router.push({ path: this.$route.path, hash: '#' + resolveSlug(this.walker) }).catch(failure => {
            if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
                return
            }
        })
    }

    updateSectionProgress() {
        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.SINGLE_SECTION_PROGRESS, {
            [this.$route.path]: this.isLast ? 1 : 0.5
        })
    }

    commitType() {
        this.$store.commit(MutationType.SLIDE_TYPE, this.walker.item['@type'])
    }

    onAssetsLoaded() {
        this.$nextTick(() => {
            this.$emit(EventType.ASSETS_LOADED)
        })
    }

    @Watch('color')
    onColorChange() {
        this.updateUIColor()
    }

    @Watch('isSlideshow')
    onIsSlideshowChange() {
        if (!this.isSlideshow) {
            this.initIntersectionObserver()
        } else {
            this.disposeIntersectionObserver()
        }
    }

    @Watch('isVisible')
    onIsVisibleChange() {
        if (!this.isVisible) {
            this.isEnoughVisible = false
        }
    }

    @Watch('presentationIsVisible')
    onPresentationIsVisibleChange() {
        if (this.presentationIsVisible && this.shouldAppear()) this.appear()
        if (this.presentationIsVisible && !this.isSlideshow && this.isCurrent) this.$el.scrollIntoView()
    }

    @Watch('transitionIsVisible')
    onTransitionIsVisibleChange() {
        if (!this.transitionIsVisible && this.shouldAppear()) this.appear()
    }

    @Watch('isEnoughVisible')
    onIsEnoughVisibleChange() {
        if (this.isEnoughVisible) {
            this.updateUIColor()
            if (this.isPresentingLeader) this.updateSectionProgress()

            if (!this.isSlideshow && this.indexX === this.currentSlideIndexX) this.setCurrentRouteHash()
        }

        this.afterIsEnoughVisibleChange()
    }

    @Watch('isPresentingLeader')
    onIsPresentingLeaderChange() {
        if (this.isPresentingLeader && this.isCurrent) this.updateSectionProgress()
    }

    @Watch('windowHeight')
    onWindowHeightChange() {
        this.disposeIntersectionObserver()
        this.initIntersectionObserver()
    }

    @Watch('currentIndex')
    onCurrentIndexChange(index: number, previousIndex: number) {
        this.previousIndex = previousIndex
        this.previousIndexX = this.currentSlideIndexX
    }

    @Watch('currentIndexX')
    onIndexXChange(indexX: number, previousIndexX: number) {
        this.previousIndexX = previousIndexX
        this.previousIndex = this.currentIndex
    }

    @Watch('isCurrent')
    onIsCurrentChange() {
        if (this.isCurrent) {
            if (
                this.walker.item['@type'] === SlideType.COLLECTION &&
                this.index === 0 &&
                this.indexX !== this.previousIndexX
            ) {
                return
            }

            this.commitType()
            this.setCurrentRouteHash()
        }
    }

    @Watch('$route')
    onRouteChange(route: Route, previousRoute: Route) {
        if (route.path !== previousRoute.path) return

        if (
            (this.$route.hash === '#' + resolveSlug(this.walker) && !this.isCurrent) ||
            (this.index === 0 && !this.$route.hash.length)
        ) {
            this.$store.commit(MutationType.SLIDE_INDEX, this.index)

            if (!this.isSlideshow) {
                const rect = this.$el.getBoundingClientRect()

                if (rect.y >= window.innerHeight || rect.bottom <= 0) {
                    gsap.to(window, {
                        scrollTo: this.$el,
                        duration: 0.8,
                        ease: 'power2'
                    })
                }
            }
        }
    }

    onIntersectionObserverChange(entries: Array<IntersectionObserverEntry>) {
        this.isEnoughVisible = entries[0].isIntersecting
    }

    onResize() {
        this.windowWidth = window.innerWidth
        this.windowHeight = window.innerHeight

        this.afterResize()
    }

    @Watch('shouldInit')
    onShouldInitChange() {
        if (this.shouldInit) this.init()
    }
}
