










































import { Component } from 'vue-property-decorator'
import Slide from '@/mixins/Slide.ts'
import { mixins } from 'vue-class-component'
import MediaContent from '@/components/MediaContent.vue'
import ArrowDownNextSlide from '@/components/ArrowDownNextSlide.vue'
import Markdown from '@/components/Markdown.vue'
import ButtonIcon from '@/components/ButtonIcon.vue'
import IconEmail from '@/assets/img/icons/email.svg?sprite'
import { getResourceUrl } from '@/utils/get-resource-url'
import ScrollerElement, { ScrollerElementAnimationEvent } from '@/components/ScrollerElement.vue'
import gsap from 'gsap'

@Component({
    components: {
        ScrollerElement,
        MediaContent,
        ArrowDownNextSlide,
        Markdown,
        ButtonIcon,
        IconEmail
    }
})
export default class SlideContact extends mixins(Slide) {
    $refs!: {
        background: MediaContent
        logo: MediaContent
        leftColumn: Markdown
        rightColumn: Markdown
        button: ButtonIcon
    }

    get walkerItem() {
        return this.walker.item as SlideWalkerItemContact
    }

    get backgroundMedia(): RoadizDocument | undefined {
        return this.walkerItem.backgroundMedia?.[0]
    }

    get vCardSrc(): string {
        return getResourceUrl(this.walkerItem.vcard[0].url) || ''
    }

    get buttonHref() {
        return this.walkerItem.contactEmail
            ? `mailto:${this.walkerItem.contactEmail}`
            : this.walkerItem.vcard.length
            ? this.vCardSrc
            : ''
    }

    populateAppear(timeline: GSAPTimeline) {
        const contentElements: Array<HTMLElement> = []

        if (this.$refs.logo) contentElements.push(this.$refs.logo.$el as HTMLElement)
        if (this.$refs.leftColumn) contentElements.push(this.$refs.leftColumn.$el as HTMLElement)
        if (this.$refs.rightColumn) contentElements.push(this.$refs.rightColumn.$el as HTMLElement)

        if (this.$refs.background) {
            timeline.fromTo(
                this.$refs.background.$el,
                {
                    opacity: 0.8,
                    scale: 1.2
                },
                {
                    opacity: 1,
                    scale: 1,
                    duration: 2.4,
                    ease: 'power2'
                },
                0.5
            )
        }

        if (contentElements.length) {
            timeline.fromTo(
                contentElements,
                {
                    opacity: 0,
                    y: 60
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1.2,
                    ease: 'power3',
                    stagger: 0.1
                },
                0.8
            )
        }

        if (this.$refs.button) {
            gsap.killTweensOf(this.$refs.button.$el) // kill the enterY tween

            timeline.fromTo(
                this.$refs.button.$el,
                {
                    scale: 0
                },
                {
                    scale: 1,
                    duration: 1.2,
                    ease: 'power3'
                },
                1.3
            )
        }
    }

    populateEnterY({ timeline }: ScrollerElementAnimationEvent) {
        const contentElements: Array<HTMLElement> = []

        if (this.$refs.logo) contentElements.push(this.$refs.logo.$el as HTMLElement)
        if (this.$refs.leftColumn) contentElements.push(this.$refs.leftColumn.$el as HTMLElement)
        if (this.$refs.rightColumn) contentElements.push(this.$refs.rightColumn.$el as HTMLElement)

        if (this.$refs.background) {
            timeline.fromTo(
                this.$refs.background.$el,
                {
                    opacity: 0.8,
                    scale: 1.2
                },
                {
                    opacity: 1,
                    scale: 1,
                    duration: 1,
                    ease: 'none'
                },
                0
            )
        }

        if (contentElements.length) {
            timeline.fromTo(
                contentElements,
                {
                    opacity: 0,
                    y: 60
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.5,
                    ease: 'power3',
                    stagger: 0.1
                },
                0.5
            )
        }

        if (this.$refs.button) {
            timeline
                .set(
                    this.$refs.button.$el,
                    {
                        scale: 0
                    },
                    0.25
                )
                .call(
                    () => {
                        gsap.to(this.$refs.button.$el, {
                            scale: 1,
                            duration: 0.8,
                            ease: 'power3'
                        })
                    },
                    [],
                    0.8
                )
        }
    }
}
